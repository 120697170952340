.grid {
  display: grid;
  row-gap: 4rem;
  margin: 3rem 1.5rem 6rem 1.5rem;
}

/* CONTAINER FOR ALL GRID ELEMENTS */
.grid-item__container:nth-of-type(n + 2) {
  height: 35rem;
  overflow: hidden;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--accent-color);
}

.grid-item__container:nth-of-type(n + 2):hover {
  cursor: pointer;
}

/* GRID HEADER */
.grid__header__title {
  font: 1.25rem/2rem var(--heading-font);
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.grid__header__body {
  margin: 0;
  transform: translateY(120%);
  opacity: 0;
}

/* GRID ITEMS – PROJECTS */
.grid-item__anchor {
  display: block;
  height: 100%;
}

.grid-item__image {
  position: relative;
  height: 50%;
  transition: 0.5s height var(--slide-up);
}

.grid-item__image#image__mass-gen {
  background: url('../media/projects/cnp.jpeg') no-repeat center/cover;
  background-size: 100%;
}
.grid-item__image#image__khan-academy {
  background: url('../media/projects/khan-academy-logo.svg') no-repeat
    center/cover;
}
.grid-item__image#image__neuroscape {
  background: url('../media/projects/neuroscape.jpg') no-repeat center/cover;
}
.grid-item__image#image__give-directly {
  background: url('../media/projects/givedirectly-logo.png') no-repeat
    center/cover;
}
.grid-item__image#image__freecodecamp {
  background: url('../media/projects/free-code-camp.svg') no-repeat center/cover;
}

.grid-item__image#image__unitedgmh {
  background: url('../media/projects/ugmh.gif') no-repeat center/cover;
}

.grid-item__image {
  /* temporary hack to avoid having to fiddle with 
    the image selectors above
   */
  background-size: 100% !important; 
}

.grid-item__image__title {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0.75rem 1.5rem 0 1.5rem;
  font: 1.5rem/2.25rem var(--heading-font);
  background-color: var(--pure-white);
  transition: 0.25s opacity var(--border-fade-in);
}

.grid-item__content {
  position: relative;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.grid-item__content__headline {
  font: var(--base-font-size) / var(--base-line-height) var(--heading-font);
  font-family: var(--heading-font);
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  margin: 1.5rem 0 0 0;
}

.grid-item__content__title {
  font: 400 1.125rem/1.75rem var(--heading-font);
  margin: 2rem 0 0 0;
}

.grid-item__content__body {
  margin: 1rem 0 0 0;
}

.grid-item__content__cta {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--accent-color);
  text-transform: uppercase;
  font: 500 0.75rem/1.25rem var(--heading-font);
  letter-spacing: 0.5px;
}

/* MEDIA QUERIES */
@media (max-width: 320px) {
  /* adjustment for very small screens */
  .grid-item__container:nth-of-type(n + 2) {
    height: 40rem;
  }

  /* .grid-item__image {
    height: 30%;
  } */
}

@media (min-width: 460px) {
  .grid {
    margin: 4rem 1.5rem 7rem 1.5rem;
  }

  .grid__header__title {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .grid-item__content__headline {
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .grid-item__content__title {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .grid__header__body {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .grid {
    margin: 4rem 2rem 7rem 2rem;
    row-gap: 5rem;
  }

  .grid-item__container:nth-of-type(n + 2) {
    height: 40rem;
  }

  .grid__header__title {
    font-size: 2.5rem;
    line-height: 4rem;
  }

  .grid-item__image {
    height: 60%;
  }

  .grid-item__content {
    height: 40%;
  }

  .grid-item__content__title {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

@media (min-width: 1024px) {
  .projects {
    padding: 0 1rem;
  }

  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    margin: 4rem auto 7rem auto;
    /* max-width lines up with hero section text grid */
    max-width: 69.5rem;
  }

  .grid-item__container {
    padding: 1rem;
  }

  .grid-item__container:first-of-type {
    align-self: center;
  }

  .grid-item__container:nth-of-type(n + 2) {
    height: 25rem;
    border-bottom: none;
    transition: 0.5s border var(--border-fade-in);
  }

  .grid-item__image {
    height: 100%;
  }

  .grid-item__image__title {
    display: block;
  }

  .grid-item__content {
    height: 100%;
  }
}

/* 
UTILITY CLASSES 
KEEP AT BOTTOM OF FILE FOR CASCADE 
*/
.collapse {
  height: 0%;
}

.fade-out {
  opacity: 0;
}

.border-in {
  border: 1px solid var(--accent-color) !important;
}
